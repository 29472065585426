import _ from 'lodash'
// eslint-disable-next-line import/no-cycle
import * as AttachmentActions from 'mgr/actualslideout/actions/AttachmentActions'
import * as MessagingAndActivityLogActions from 'mgr/actualslideout/actions/MessagingAndActivityLogActions'
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import * as PaymentActions from 'mgr/actualslideout/actions/PaymentActions'
// eslint-disable-next-line import/no-cycle
import * as ReviewActions from 'mgr/actualslideout/actions/ReviewActions'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import ActualServices from 'mgr/lib/services/ActualServices'
import FollowerServices from 'mgr/lib/services/FollowerServices'
import { batchActions } from 'svr/common/ReduxUtils'
import { getVenueToday, isPastTime } from 'svr/common/TimeUtil'
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import * as ActionTypes from './ActionTypes'
import * as BookAvailabilityActions from './BookAvailabilityActions'
import * as BookClientActions from './BookClientActions'
import * as BookDetailsActions from './BookDetailsActions'
import * as SlideoutActions from './SlideoutActions'

/** Prepare the flyout to view an actual * */
const setViewMode = (actualId, venueId) => ({
  type: ActionTypes.VIEW_ACTUAL,
  actualId,
  venueId,
})

/** Fetching actuals * */
const getActualBegin = () => ({ type: ActionTypes.LOAD_ACTUAL_START })
const getActualSuccess = actual => ({
  type: ActionTypes.LOAD_ACTUAL_SUCCESS,
  actual,
})
const getActualFail = () => ({ type: ActionTypes.LOAD_ACTUAL_FAIL })

const tryGetActual = (actualId, inboundVenueId) => (dispatch, getState) => {
  const store = getState()

  let venue
  if (inboundVenueId) {
    const isSameVenueAsRequested = store.appState.venue.id === inboundVenueId
    // This attempts to avoid causing an error IF the actual is on the current
    // venue and the userDomain venues have not loaded.
    venue = isSameVenueAsRequested ? store.appState.venue : _.find(store.appState.userDomain.venues, { id: inboundVenueId })
  } else {
    venue = store.appState.venue
  }

  if (_.isNil(venue)) {
    throw new Error('Venue is undefined when trying to view an actual')
  }

  const venueId = venue.urlKey || venue.id

  dispatch(batchActions([getActualBegin(), setViewMode(actualId, venueId), BookAvailabilityActions.enableLockToAvoidGettingANewResHold()]))

  const errHandler = error =>
    dispatch(batchActions([getActualFail(), SlideoutActions.showNotificationError(`Error getting reservation details: ${error}`)]))
  return ActualServices.fetchActual(venueId, actualId, errHandler).then(actual => dispatch(getActualSuccess(actual)))
}

const refreshActualSuccess = actual => ({
  type: ActionTypes.REFRESH_ACTUAL_SUCCESS,
  actual,
})

const refreshActual = () => (dispatch, getState) => {
  const state = getState()
  const venue = state.bookState.selectedVenue
  const venueId = venue.urlKey || venue.id
  const actualId = state.viewResState.actual.id
  const errHandler = error =>
    dispatch(batchActions([getActualFail(), SlideoutActions.showNotificationError(`Error refreshing reservation details: ${error}`)]))
  return ActualServices.fetchActual(venueId, actualId, errHandler).then(actual => dispatch(refreshActualSuccess(actual)))
}

const setViewVenue = (viewVenue, canEditReservation, isInService) => ({
  type: ActionTypes.SET_VIEW_VENUE,
  viewVenue,
  canEditReservation,
  isInService,
})

export const isPastActualTime = (actual, venue) =>
  isPastTime(actual.arrival_time_sort_order, venue.timezone, venue.startOfDayHour, actual.date_moment)

const tryShowActual = (actualId, inboundVenueId, onComplete) => (dispatch, getState) =>
  Promise.all([dispatch(tryGetActual(actualId, inboundVenueId)), dispatch(GlobalActions.ensureHaveBookingInfo(inboundVenueId))]).then(
    () => {
      const state = getState()
      const { actual } = state.viewResState
      const venueId = actual.venue_id
      const venue = _.find(state.appState.userDomain.venues, { id: venueId })
      showLoadedActual(dispatch, getState, actual, venue)
      if (onComplete) {
        onComplete()
      }
    }
  )

export const onBookReservationSuccess = (dispatch, getState, actual, venue) => {
  Promise.resolve(dispatch(getActualSuccess(actual))).then(() => showLoadedActual(dispatch, getState, actual, venue, true))
}

const showLoadedActual = (dispatch, getState, actual, venue, bookReservationSuccess) => {
  const venueId = venue.id
  const actualId = actual.id
  const date = actual.date_moment
  const viewVenue = venue || {}
  const isInService =
    _.findIndex(
      viewVenue.serviceStatuses['in-service'] || [],
      serviceStatus => actual.status === serviceStatus.db_name && !serviceStatus.deleted && serviceStatus.enabled
    ) > -1

  const canEditReservation = actual.is_res_editable && !isInService

  dispatch(
    batchActions([
      setViewVenue(venue, canEditReservation, isInService),
      BookAvailabilityActions.copyViewActualsToBookDetails(actual, venue),
    ])
  )

  if (canEditReservation) {
    BookAvailabilityActions.onChangeVenuesOrDate(dispatch, getState, [venueId], date)
    BookDetailsActions.onChangeSelectedVenue(dispatch, getState, venueId)
    BookClientActions.onChangeSelectedVenue(dispatch, getState, venueId)
  }

  const actions = [
    ReviewActions.tryGetLinkedReviews(),
    SlideoutActions.tryGetAutoAssignmentsAndProblems(),
    MessagingAndActivityLogActions.tryGetActivityLog(),
    MessagingAndActivityLogActions.tryGetMessages(venueId),
    AttachmentActions.tryGetAttachments(venueId),
    PaymentActions.tryGetTransactions(venueId, actualId, true),
  ]
  if (bookReservationSuccess) {
    actions.push(BookAvailabilityActions.enableLockToAvoidGettingANewResHold())
  }
  return dispatch(batchActions(actions))
}

const getPutStatusAssignmentStart = () => ({
  type: ActionTypes.SEND_STATUS_ASSIGNMENT_START,
})
const getPutStatusAssignmentSuccess = status => ({
  type: ActionTypes.SEND_STATUS_ASSIGNMENT_SUCCESS,
  status,
})
const getPutStatusAssignmentFail = () => ({
  type: ActionTypes.SEND_STATUS_ASSIGNMENT_FAIL,
})

const tryPutStatusAssignment = (status, updatedActualId, updatedVenueId) => (dispatch, getState) => {
  dispatch(getPutStatusAssignmentStart())

  const { viewResState } = getState()
  const actualId = updatedActualId || viewResState.actual.id
  const venueId = updatedVenueId || viewResState.viewVenue.urlKey || viewResState.viewVenue.id

  const errHandler = error =>
    dispatch(batchActions([getPutStatusAssignmentFail(), SlideoutActions.showNotificationError(`Error setting status: ${error}`)]))
  return ActualServices.postResStatusChange(venueId, actualId, status, errHandler).then(status => {
    const autoAssignmentsAndProblemResPromise = dispatch(SlideoutActions.tryGetAutoAssignmentsAndProblems(actualId))
    const putStatusAssignmentResponse = dispatch(getPutStatusAssignmentSuccess(status))
    dispatch(
      batchActions([
        SlideoutActions.postbackActualOnEdit(autoAssignmentsAndProblemResPromise),
        PaymentActions.tryGetTransactions(venueId, actualId, true),
        refreshActual(),
      ])
    )
    if (status.status === 'CANCELED') {
      Pmp.Manager.Reservations.Day.cancel_handler(actualId)
    }
    if (
      (viewResState.actual.status === 'CANCELED' || viewResState.actual.status === 'CANCELED_AND_NOTIFY') &&
      status.status !== 'CANCELED' &&
      status.status !== 'CANCELED_AND_NOTIFY'
    ) {
      Pmp.Manager.Reservations.Day.uncancel_handler(actualId)
    }
    return putStatusAssignmentResponse
  })
}

const changeSelectedTables = selectedTableIds => ({
  type: ActionTypes.VIEW_ACTUAL_CHANGE_SELECTED_TABLES,
  selectedTableIds,
})
const changeIsCustomAssign = isCustomAssign => ({
  type: ActionTypes.VIEW_ACTUAL_CHANGE_IS_CUSTOM_ASSIGN,
  isCustomAssign,
})
const tableChangeSaved = () => ({
  type: ActionTypes.VIEW_ACTUAL_TABLE_CHANGE_SAVED,
})

const getPutTableAssignmentStart = () => ({
  type: ActionTypes.SEND_TABLE_ASSIGNMENT_START,
})
const getPutTableAssignmentSuccess = actualSeating => ({
  type: ActionTypes.SEND_TABLE_ASSIGNMENT_SUCCESS,
  actualSeating,
})
const getPutTableAssigmentFail = () => ({
  type: ActionTypes.SEND_TABLE_ASSIGNMENT_FAIL,
})

const tryPutTableAssignment = (tables, tablePath) => (dispatch, getState) => {
  const store = getState()
  const { viewResState } = store
  const { actual, selectedTableIds, isAutoAssign, isCustomAssign, hasTableDropdownChange } = viewResState
  if (!hasTableDropdownChange) {
    svrDebug('No table edits detected, ignoring table assignment')
    return undefined
  }
  dispatch(getPutTableAssignmentStart())

  const actualId = actual.id
  const venueId = actual.venue_id
  const tableIds = selectedTableIds.reduce((result, table) => Array.prototype.push.apply(result, table.value.split(',')) && result, [])
  if (isCustomAssign) {
    tableIds.push('custom')
  }

  const errHandler = error =>
    dispatch(batchActions([getPutTableAssigmentFail(), SlideoutActions.showNotificationError(`Error setting table: ${error}`)]))
  return ActualServices.postResTableAssignment({
    venueId,
    actualId,
    tableIds,
    isAutoAssign,
    isCustomAssign,
    errHandler,
  }).then(response => {
    const autoAssignmentsAndProblemResPromise = dispatch(SlideoutActions.tryGetAutoAssignmentsAndProblems(actualId))
    const putTableAssignmentResponse = dispatch(getPutTableAssignmentSuccess(response))
    dispatch(tableChangeSaved())
    dispatch(SlideoutActions.postbackActualOnEdit(autoAssignmentsAndProblemResPromise))
    return putTableAssignmentResponse
  })
}

const getDeleteFollowerStart = () => ({
  type: ActionTypes.DELETE_FOLLOWER_START,
})
const getDeleteFollowerFail = () => ({ type: ActionTypes.DELETE_FOLLOWER_FAIL })
const getDeleteFollowerSuccess = followers => ({
  type: ActionTypes.DELETE_FOLLOWER_SUCCESS,
  followers,
})

const tryDeleteFollower = followerId => (dispatch, getState) => {
  dispatch(getDeleteFollowerStart())

  const { viewResState } = getState()
  const actualId = viewResState.actual.id

  const errHandler = error =>
    dispatch(batchActions([getDeleteFollowerFail(), SlideoutActions.showNotificationError(`Error removing follower: ${error}`)]))

  return FollowerServices.deleteFollower(actualId, followerId, errHandler).then(followers => dispatch(getDeleteFollowerSuccess(followers)))
}

const getAddFollowerStart = () => ({ type: ActionTypes.ADD_FOLLOWER_START })
const getAddFollowerFail = () => ({ type: ActionTypes.ADD_FOLLOWER_FAIL })
const getAddFollowerSuccess = followers => ({
  type: ActionTypes.ADD_FOLLOWER_SUCCESS,
  followers,
})

const tryAddFollower = followerId => (dispatch, getState) => {
  dispatch(getAddFollowerStart())

  const { viewResState } = getState()
  const actualId = viewResState.actual.id

  const errHandler = error =>
    dispatch(batchActions([getAddFollowerFail(), SlideoutActions.showNotificationError(`Error adding follower: ${error}`)]))

  return FollowerServices.addFollower(actualId, followerId, errHandler).then(followers => dispatch(getAddFollowerSuccess(followers)))
}

const selectTab = tabAction => ({
  type: ActionTypes.SELECT_ACTIVITY_LOG_COMMENTS_MESSAGING_TAB,
  tabAction,
})

const OVERFLOW_MENU_ACTIONS = {
  COPY_TO_NEW_RES: 'Copy to new Reservation',
}
const forwardOverflowMenuAction = choice => (dispatch, getState) => {
  const choiceVal = OVERFLOW_MENU_ACTIONS[choice]
  const { actual, viewVenue } = getState().viewResState
  // eslint-disable-next-line default-case
  switch (choiceVal) {
    case OVERFLOW_MENU_ACTIONS.COPY_TO_NEW_RES: {
      const toDispatch = [
        SlideoutActions.enterCloneReservation({
          actual,
          accountId: viewVenue.accountId,
          connectedSetupIntents: viewVenue.connectedSetupIntents,
        }),
        SlideoutActions.resetBookedByNames({}),
      ]
      if (isPastActualTime(actual, viewVenue)) {
        toDispatch.push(BookAvailabilityActions.changeDate(getVenueToday(viewVenue.timezone, viewVenue.startOfDayHour)))
      }
      dispatch(batchActions(toDispatch))
    }
  }
}

const toggleSpendDetails = () => ({ type: ActionTypes.SPEND_TOGGLE_DETAILS })
const toggleSpendList = () => ({ type: ActionTypes.SPEND_TOGGLE_LIST })
const toggleFeedbackDetails = () => ({
  type: ActionTypes.FEEDBACK_TOGGLE_DETAILS,
})

export {
  refreshActual,
  tryShowActual,
  selectTab,
  tryPutStatusAssignment,
  changeSelectedTables,
  changeIsCustomAssign,
  tryPutTableAssignment,
  tryDeleteFollower,
  tryAddFollower,
  forwardOverflowMenuAction,
  toggleSpendDetails,
  toggleSpendList,
  toggleFeedbackDetails,
  OVERFLOW_MENU_ACTIONS,
}
